import { search } from '@/module/Search.js';
export var searchSplit = (search_term, type,pack_type) => {
    var num_array = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
    var char_array = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    if (num_array.some(char => search_term.includes(char)) && !char_array.some(char => search_term.includes(char))) {
        var UI_COMPONENT_MAP = {
            'entity' : ['order'],
            'spa':['order'],
            'allowedapp' : ['order'],
            'food':['order'],
            'app_settings':['serial_no'],
            'bundle':['name','cost','mrp'],
            'org':['mobile_number'],
            'channel':['lcn_number'],
            'wallet':['transaction_ref_number'],
            'qr_transaction':['days'],
            'version' : ['version'],
            'cdn': ['lan_ip', 'wan_ip']
        }
    } 
    else {
        var UI_COMPONENT_MAP = {
            'entity' : ['entity_name', 'entity_display_name'],
            'end_pointer':['endpoint_name'],
            'role':['name'],
            'module':['name', 'context'],
            'privilege':['name'],
            'upstream':['upstream_url','upstream_name'],
            'room':['serial_number','room_number'],
            'booking':['guest_name','mobile_number'],
            'allowedapp' : ['name','app_signature','image','apk'],
            'zeelive':['channel_name','language', 'genres', 'media_url', 'deeplink_url'],
            'wallet':['transaction_ref_number'],
            'invoice':['org_name', 'bundle_name'],
            'food':['name'],
            'guest-services':['name'],
            'places':['name', 'description'],
            'spa':['name', 'description'],
            'restaurant':['restaurant', 'address','distance'],
            'room-category':['category'],
            'sub-service-group':['sub_service_group_name'],
            'service-group':['service_grp_name'],
            'communication-group':['communication_grp_name'],
            'whatsapp':['reference_name', 'template_name'],
            'order' : ['serial_number', 'food_name', 'room_number', 'guest_name'],
            'template' : ['template_name'],
            'user' : ['user_name'],
            'vendor' : ['vendor_name'],
            'subscription' : ['name'],
            'provider' : ['name'],
            'channel' : ['name'],
            'cloud_tv': ['channel_name'],
            'tag': ['tag_name'],
            'hotel_videos': ['name'],
            'language': ['name'],
            'bundle': ['name'],
            'org':['org_name'],
            'app_settings': ['serial_no', 'transmit_as', 'app_setting_type'],
            'bundle':['name'],
            'inventory':['mac_address'],
            'org':['org_name'],
            'state':['name'],
            'qr_transaction':['serial_number','status'],
            'package':['name'],
            'referrer': ['name', 'code', 'company_name'],
            'version':['url'],
            'cop_edges': ['name'],
            'cdn': ['lan_ip', 'wan_ip', 'upstream', 'cdn_status']
        }
    }
    var num = 1
    var array = UI_COMPONENT_MAP[type]?UI_COMPONENT_MAP[type]:[]
    var payload = {
        'arr' : array,
        'term': search_term,
        'type': type,
    }
    if(pack_type) payload.pack_type=pack_type
    return new Promise(resolve => {
        return search(payload).then(data => {
            var List = data.rows.map(item => {
                item.id = num++
                return item
            })
            return resolve(List);
        })
    })
};