import config from '@/router/config';
import axios from 'axios';
export async function get(api, loadingPlugin, toasterPlugin) {
    let loader = loadingPlugin.show({ width: 40, height: 40 });
    try {
        const response = await axios.get(config.host + api);
        loader.hide();
        const data = (response.data.rows) ? response.data.rows.map((item, index) => {
            item.id = index + 1;
            item.close = false;
            return item;
        }) : response.data;
        return { "data":data, "count":response.data.count };
    } catch (error) {
        loader.hide();
        const errorMessage = error.response ? error.response.data : 'An error occurred.';
        toasterPlugin.error(errorMessage);
    };
};
